export const getStartPageByRole = (role: string | undefined) => {
  let destination
  switch (role) {
    case 'reader':
    case 'superreader':
      destination = '/bookshelf'
      break
    case 'book':
      destination = '/book'
      break
    case 'hlo-admin':
      destination = '/bookshelf'
      break
    case 'librarian':
      destination = '/bookshelf'
      break
    default:
      destination = '/'
  }
  return destination
}
