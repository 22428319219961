import { Stack } from '@mui/material'
import { Box, useTheme } from '@mui/system'
import Image from 'next/image'
import { ReactElement } from 'react'

export type HeroProps = {
  children?: ReactElement
  imageSrc: string
}
const Hero = ({ children, imageSrc }: HeroProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        height: '480px',
        position: 'relative',
      }}
    >
      <Image
        objectFit="cover"
        src={imageSrc}
        alt="Hero image"
        unoptimized={process.env.NODE_ENV === 'development'}
        layout="fill"
      />
      <Stack
        height="100%"
        justifyContent="center"
        sx={{
          position: 'absolute',
        }}
        width="100%"
      >
        {children}
      </Stack>
    </Box>
  )
}

export default Hero
