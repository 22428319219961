import {
  Box,
  Button,
  Container,
  Link as MuiLink,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/system'
import type { GetStaticProps } from 'next'
import { signIn } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Feedback from '../components/Feedback'
import Hero from '../components/Hero'
import commonTranslations from '../constants/commonTranslations'
import { useGetUserVerificationDataByUserIdQuery } from '../generated/graphql'
import useAuthClaims from '../hooks/useAuthClaims'
import COLORS from '../styles/colors'
import { NextPageWithLayout } from '../types/global'
import { getStartPageByRole } from '../util/getStartPageByRole'

const Home: NextPageWithLayout = () => {
  const { t } = useTranslation(['home'])
  const router = useRouter()
  const {
    claims,
    isAuthenticated,
    loading: claimsLoading,
    userId,
  } = useAuthClaims()

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetUserVerificationDataByUserIdQuery({
    fetchPolicy: 'no-cache',
    skip: !userId,
    variables: {
      id: userId,
    },
  })
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)

  const user = userData?.user_by_pk || undefined
  const person = user?.person

  const emailVerified = person?.emailverified
  const role = claims?.role
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const loading = claimsLoading || userLoading

  useEffect(() => {
    if (
      !isRedirecting &&
      !loading &&
      isAuthenticated &&
      emailVerified &&
      role !== 'guest'
    ) {
      if (!person?.name || !person.person_get_has_accepted_latest_eula) {
        router.push('/onboarding')
        setIsRedirecting(true)
      } else {
        router.push(getStartPageByRole(claims?.role))
        setIsRedirecting(true)
      }
    }
  }, [emailVerified, loading, isAuthenticated, role])

  return (
    <>
      <Head>
        <title>{t('pageTitle', 'Human Library')}</title>
      </Head>
      {isRedirecting || loading ? (
        <Container maxWidth="xl" sx={{ minHeight: '70vh' }}>
          <Feedback type="loading" />
        </Container>
      ) : (
        <>
          <Hero imageSrc="/hero_unjudge.png" />
          <Container maxWidth="xl" component="main" id="main-content">
            <Stack
              alignItems="center"
              spacing="80px"
              paddingBottom="100px"
              width="100%"
              sx={{
                background: 'url(/background_overlay.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPositionX: 'right',
                backgroundPositionY: 'top 46px',
              }}
            >
              <Box
                sx={{
                  pt: '86px',
                  paddingBottom: '2rem',
                  textAlign: 'center',
                }}
              >
                <Stack alignItems="center" spacing="8px">
                  <Typography
                    variant="garage_h2"
                    component="h1"
                    color={COLORS.greyDark}
                  >
                    {t('header')}
                  </Typography>
                  <Typography variant="inter_bodyText" color={COLORS.greyDark}>
                    {t('subHeader')}
                  </Typography>
                  <Typography variant="inter_bodyText" color={COLORS.greyDark}>
                    {!isAuthenticated &&
                      'You need to log in as a Reader, Book, or Librarian to access the Human Library Online.'}
                    {isAuthenticated &&
                      !emailVerified &&
                      t('Email not verified title')}

                    {isAuthenticated &&
                      emailVerified &&
                      claims?.role === 'guest' &&
                      t('Guest welcome title')}
                  </Typography>
                  <Stack spacing="4px" alignItems="center" textAlign="center">
                    {!isAuthenticated && (
                      <Button
                        sx={{ width: ['220px', '320px'], mt: '40px' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => signIn('auth0')}
                      >
                        {'LOG IN'}
                      </Button>
                    )}
                    {isAuthenticated && !emailVerified && (
                      <Typography variant="inter_bodyText">
                        {t('Email not verified')}
                      </Typography>
                    )}
                    {isAuthenticated &&
                      claims &&
                      emailVerified &&
                      ['guest'].includes(claims.role) && (
                        <Typography variant="inter_bodyText">
                          {t('Guest welcome message')}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Container>
        </>
      )}
    </>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', [
        'home',
        ...commonTranslations,
      ])),
    },
  }
}

export default Home
